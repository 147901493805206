import type { ComponentType, JSX } from 'react'
import dynamic from 'next/dynamic'

import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { setSx } from '@knauf-group/ct-designs/utils/utils'
import type { TypePageContentSkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types/TypePageContent'
import type { TypePageEntrySkeleton } from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types/TypePageEntry'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import Box from '@mui/material/Box'

import type { CMSContentTypes, CMSLayoutProps, CMSSectionProps } from '.'
import { useStyles } from './CMSLayout.styles'

const SmallHeroTeaserWrapper = dynamic(() =>
  import('../../SmallHeroTeaser').then((mod) => mod.default),
)
const Teaser = dynamic(() => import('@components/Teaser').then((mod) => mod.Teaser))
const TextBodyWrapper = dynamic(() =>
  import('../../TextBodyWrapper').then((mod) => mod.default),
)
const RibbonWrapper = dynamic(() => import('../../Ribbon').then((mod) => mod.default))
const ImageGalleryTextComboWrapper = dynamic(() =>
  import('../../ImageGalleryTextComboWrapper').then((mod) => mod.default),
)
const AccordionWrapper = dynamic(() => import('../../Accordion').then((mod) => mod.default))
const CTARepeaterWrapper = dynamic(() => import('../../CTARepeater').then((mod) => mod.default))
const CardsWrapper = dynamic(() => import('../../CardsGroup').then((mod) => mod.default))
const VideoTileListWrapper = dynamic(() =>
  import('../../VideoTileListWrapper').then((mod) => mod.default),
)
const DocumentsGalleryWrapper = dynamic(() =>
  import('../../DocumentsGallery').then((mod) => mod.default),
)
const ContactsGalleryWrapper = dynamic(() =>
  import('../../ContactsGallery').then((mod) => mod.default),
)
const HubSpotWrapper = dynamic(() =>
  import('@cms/components/HubSpotForm').then((mod) => mod.default),
)
const AddressBookWrapper = dynamic(() => import('../../AddressBook').then((mod) => mod.default))
const StageWrapper = dynamic(() => import('../../Stage').then((mod) => mod.default))
const SlidingStage = dynamic(() =>
  import('@components/SlidingStage').then((mod) => mod.default),
)

export const LAYOUT_SIZE = {
  minWidth: '18rem', // 288px
  maxWidth: '90rem', // 1440px
}

const SPACING_EXCLUDED_CONTENT_TYPES = [
  CONTENTFUL_TYPES.CONTENT_SMALL_HERO_TEASER,
  CONTENTFUL_TYPES.CONTENT_RIBBON,
  CONTENTFUL_TYPES.CONTENT_STAGE,
]

const spacingExcludedComponentsStyles: Styles[] = SPACING_EXCLUDED_CONTENT_TYPES.map(
  (contentType) => {
    const key = `& .${contentType} > div, .${contentType}:first-of-type > div, .${contentType}:last-of-type > div`
    return {
      [key]: {
        py: 'inherit',
      },
    }
  },
)

export const CMSSection: React.FC<CMSSectionProps> = ({ contentModel }) => {
  const componentMap = {
    [CONTENTFUL_TYPES.CONTENT_CTA_REPEATER]: CTARepeaterWrapper,
    [CONTENTFUL_TYPES.CONTENT_SMALL_HERO_TEASER]: SmallHeroTeaserWrapper,
    [CONTENTFUL_TYPES.CONTENT_TEXT]: TextBodyWrapper,
    [CONTENTFUL_TYPES.CONTENT_RIBBON]: RibbonWrapper,
    [CONTENTFUL_TYPES.CONTENT_TEASER]: Teaser,
    [CONTENTFUL_TYPES.CONTENT_ACCORDION]: AccordionWrapper,
    [CONTENTFUL_TYPES.CONTENT_CARD]: CardsWrapper,
    [CONTENTFUL_TYPES.CONTENT_DOCUMENT_GALLERY]: DocumentsGalleryWrapper,
    [CONTENTFUL_TYPES.CONTENT_CONTACT_GALLERY]: ContactsGalleryWrapper,
    [CONTENTFUL_TYPES.CONTENT_VIDEO_GALLERY]: VideoTileListWrapper,
    [CONTENTFUL_TYPES.CONTENT_IMAGE_GALLERY]: ImageGalleryTextComboWrapper,
    [CONTENTFUL_TYPES.CONTENT_FORM]: HubSpotWrapper,
    [CONTENTFUL_TYPES.CONTENT_ADDRESS_BOOK]: AddressBookWrapper,
    [CONTENTFUL_TYPES.CONTENT_STAGE]: StageWrapper,
    [CONTENTFUL_TYPES.CONTENT_STAGE_SLIDING_GALLERY]: SlidingStage,
  }

  const CMSComponent: ComponentType<any> = componentMap[contentModel.sys.contentType?.sys.id]

  if (!CMSComponent) {
    return null
  }

  return <CMSComponent {...contentModel} />
}

const RenderSection = ({ sectionKey, nameOfClass, style, contentModel }) => (
  <Box key={sectionKey} component="section" className={nameOfClass} sx={style}>
    <CMSSection contentModel={contentModel} />
  </Box>
)
/** In certain pages there is no hover effect for the Product Title.
 * To enable hover effect set enableHoverEffect true.
 */
const CMSLayout: React.FC<CMSLayoutProps> = ({ content }) => {
  const styles: Styles = useStyles()

  const hasSectionStageInPage = 'sectionStage'
  const hasSectionRibbonInPage = 'sectionRibbon'

  return (
    <Box sx={[styles.sectionWrapper, ...setSx(spacingExcludedComponentsStyles)]}>
      {content.fields &&
        hasSectionStageInPage in content.fields && ( // Check for a section Stage in Page Type
          <RenderSection
            sectionKey={`${
              (content as ContentEntry<TypePageContentSkeleton>).fields?.sectionStage.sys.id
            }`}
            nameOfClass={
              (content as ContentEntry<TypePageContentSkeleton>).fields?.sectionStage.sys
                .contentType?.sys.id
            }
            style={styles.sectionSpacing}
            contentModel={content.fields.sectionStage}
          />
        )}
      {content.fields &&
        hasSectionRibbonInPage in content.fields && ( // Check for a section Ribbon in Page Type
          <RenderSection
            sectionKey={`${
              (content as ContentEntry<TypePageEntrySkeleton>).fields?.sectionRibbon.sys.id
            }`}
            nameOfClass={
              (content as ContentEntry<TypePageEntrySkeleton>).fields?.sectionRibbon.sys
                .contentType?.sys.id
            }
            style={styles.sectionSpacing}
            contentModel={content.fields.sectionRibbon}
          />
        )}
      {(content.fields?.sectionBody as ContentEntry<CMSContentTypes>[])
        ?.filter((component) => component.fields) // Filter out draft content from contentful
        .map((contentModel): JSX.Element => {
          // @ts-ignore
          // TODO KC-499 evaluate whether this condition is needed at all.
          // According to the typings sys.type can never be "Link". It can only be "Entry"
          // Therefore this condition is effectively useless
          if (contentModel.sys.type === CONTENTFUL_TYPES.LINKED_CONTENT) {
            return <></>
          }

          return (
            <Box
              key={`${contentModel.sys.id}`}
              component="section"
              className={`${contentModel.sys.contentType?.sys.id}`}
              sx={[styles.sectionSpacing]}
            >
              <CMSSection contentModel={contentModel} />
            </Box>
          )
        })}
    </Box>
  )
}

export default CMSLayout
